import $ from 'jquery';

// Foundation JS relies on a global variable. In ES6, all imports are hoisted
// to the top of the file so if we used `import` to import Foundation,
// it would execute earlier than we have assigned the global variable.
// This is why we have to use CommonJS require() here since it doesn't
// have the hoisting behavior.
window.jQuery = $;

require('retinajs/dist/retina.js')
require('jquery-mousewheel/jquery.mousewheel.js')
require('jquery.easing/jquery.easing.js')
require('imagesloaded/imagesloaded.pkgd.js')
require('plyr/dist/plyr.js')
require('swiper/dist/js/swiper.js')
require('jquery-collageplus/extras/jquery.removeWhitespace.js')
// require('jquery-collageplus/jquery.collagePlus.js')
// require('./node_modules/pinch-zoom-js/dist/pinch-zoom.js')

require('./vendor/achtung-themes')

require('./modules/base')